


<template>
  <CCard class="h-100" :accent-color="accent" >
    <CCardHeader class="dashboard-card-header m-0 p-1" :color="color" >
      <CRow class="p-0">
        
      <CCol col="11" class="">
      <slot name="header">
        
      </slot>
      </CCol>
      <CCol class="col float-center  ">
      <div v-if="collapsible" >
      <CLink
        class="card-header-action btn-minimize float-right"
        @click="isCollapsed = !isCollapsed"
      >
        <CIcon :color="textColor"  :name="`cil-chevron-${!isCollapsed ? 'bottom' : 'top'}`" />
      </CLink>
      </div>
      </CCol>
      </CRow>
    </CCardHeader>
    <CScrollbar>
    <CCollapse :show="!isCollapsed" :duration="400" class="overflow-auto">
      <CCardBody :color="bodyColor" class="overflow-auto py-0 ">
        <slot name="body"></slot>
      </CCardBody>
      
    </CCollapse>
    </CScrollbar>
  </CCard>
</template>




<style >

.dashboard-card-header{
  color: var(--light-gray); 
}

.standard-card-header{
   color: var(--light-gray); 
}
.cards-label {
  font-family: var(--font-family-sans-serif);
  font-size: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: lighter;
}
.cards-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-family-sans-serif);
  /*color: var(--light-gray);*/
  font-size: 15px;
  margin-bottom: 0px;
}
</style>


<script>
export default {
  name: "DashboardCard",
  props: {
    color:{
      type: String,
      default: "" // "gradient-warning";
    },
    accent:{
      type: String,
    },
    collapsible: {
      type:Boolean,
      default: false,
    },
    bodyColor:{
      type: String, 
      default: ""

    }, 
    textColor:{
      type: String, 
      default: "light"
    },
    collapsed:{
      type: Boolean, 
      default: false, 
    }
    
  },

  data() {
    return {
      isCollapsed: true,
    };
  },
  mounted(){
    if(this.collapsible=== true){
      this.isCollapsed = this.collapsed;
    }
    else{
      this.isCollapsed = false; 
    }
  }
};
</script>

