var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "h-100", attrs: { "accent-color": _vm.accent } },
    [
      _c(
        "CCardHeader",
        {
          staticClass: "dashboard-card-header m-0 p-1",
          attrs: { color: _vm.color }
        },
        [
          _c(
            "CRow",
            { staticClass: "p-0" },
            [
              _c("CCol", { attrs: { col: "11" } }, [_vm._t("header")], 2),
              _c("CCol", { staticClass: "col float-center  " }, [
                _vm.collapsible
                  ? _c(
                      "div",
                      [
                        _c(
                          "CLink",
                          {
                            staticClass:
                              "card-header-action btn-minimize float-right",
                            on: {
                              click: function($event) {
                                _vm.isCollapsed = !_vm.isCollapsed
                              }
                            }
                          },
                          [
                            _c("CIcon", {
                              attrs: {
                                color: _vm.textColor,
                                name:
                                  "cil-chevron-" +
                                  (!_vm.isCollapsed ? "bottom" : "top")
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CScrollbar",
        [
          _c(
            "CCollapse",
            {
              staticClass: "overflow-auto",
              attrs: { show: !_vm.isCollapsed, duration: 400 }
            },
            [
              _c(
                "CCardBody",
                {
                  staticClass: "overflow-auto py-0 ",
                  attrs: { color: _vm.bodyColor }
                },
                [_vm._t("body")],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }